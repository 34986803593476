require('./clean.css');
require('./layout.css');
require('./footer.css');
require('./about-us.css');
// require('./ultimate-testimonial-slider.css');
require('./Animated-Typing-With-Blinking.css');
require('./Article-List.css');
require('./Features-Cards.css');
require('./Features-Clean.css');
require('./Footer-Basic.css');
require('./Navigation-with-Button.css');
require('./Testimonials.css');